<template>
  <div class="site-container page-service-transport">
    <ab-header></ab-header>

    <div class="site-banner">
      <img src="@/assets/img/website/banner-service-transport-abroad.png" />
    </div>

    <div class="site-module">
      <div class="sm-hd">运输网络分布</div>
      <div class="sm-desc">全球重点覆盖国家<b>70+</b>&nbsp;&nbsp;重点线路<b>20+</b></div>
      <div class="sm-bd service-transport"></div>
    </div>

    <div class="site-module">
      <div class="sm-hd">主要运输产品</div>
      <div class="sm-bd service-product">
        <div class="item">
          <i></i>
          <span>集成电路产品</span>
        </div>
        <div class="item">
          <i></i>
          <span>高值易碎产品<br />晶圆等</span>
        </div>
        <div class="item">
          <i></i>
          <span>恒温恒湿减震设备<br />光刻机等</span>
        </div>
        <div class="item">
          <i></i>
          <span>制造工具<br />探针卡等</span>
        </div>
        <div class="item">
          <i></i>
          <span>制造原材料<br />晶棒等</span>
        </div>
        <div class="item">
          <i></i>
          <span>工业软件<br />光盘等</span>
        </div>
        <div class="item">
          <i></i>
          <span>普通半导体料件、成品、设备</span>
        </div>
        <div class="item">
          <i></i>
          <span>半导体材料<br />光刻胶、表面活性清洗剂等</span>
        </div>
      </div>
    </div>

    <div class="site-module">
      <div class="sm-hd">优势</div>
      <div class="sm-bd service-advantage">
        <div class="item">
          根据货物不同规格和类型，<br />
          提供满足您需求的物流方案。
        </div>
        <div class="item">
          与众多一流的航空公司合作，有多条线路包机包板，能够在保证仓位的情况下提供路线选择和稳定的运输时效。
        </div>
        <div class="item">
          并且有专人监督打航空板，<br />
          物理上降低破损率。
        </div>
        <div class="item">
          熟悉货物特性和各大半导体厂操作流程。
        </div>
      </div>
    </div>

    <ab-footer></ab-footer>
  </div>
</template>

<script>
export default {
  name: 'service-transport-abroad',
  components: {

  },

  data () {
    return {
      
    }
  },

  computed: {

  },

  watch: {

  },

  mounted() {

  },

  methods: {
    
  }
}
</script>

<style lang="scss">
.page-service-transport {
  .service-transport {
    width: 100%;
    height: 641px;
    background: url("../../assets/img/website/transport.png") no-repeat center;
    background-size: contain;
  }
  .service-product {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .item {
      width: 260px;
      height: 230px;
      margin-bottom: 30px;
      &:nth-child(1) {
        i {
          background-image: url("../../assets/img/website/sp-1.png");
        }
      }
      &:nth-child(2) {
        i {
          background-image: url("../../assets/img/website/sp-2.png");
        }
      }
      &:nth-child(3) {
        i {
          background-image: url("../../assets/img/website/sp-3.png");
        }
      }
      &:nth-child(4) {
        i {
          background-image: url("../../assets/img/website/sp-4.png");
        }
      }
      &:nth-child(5) {
        i {
          background-image: url("../../assets/img/website/sp-5.png");
        }
      }
      &:nth-child(6) {
        i {
          background-image: url("../../assets/img/website/sp-6.png");
        }
      }
      &:nth-child(7) {
        i {
          background-image: url("../../assets/img/website/sp-7.png");
        }
      }
      &:nth-child(8) {
        i {
          background-image: url("../../assets/img/website/sp-8.png");
        }
      }
      i {
        display: flex;
        width: 130px;
        height: 130px;
        margin: 0 auto;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
      span {
        display: block;
        margin-top: 20px;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        color: #000;
      }
    }
  }
  .service-advantage {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .item {
      width: calc(50% - 16px);
      height: 240px;
      margin-bottom: 30px;
      padding: 50px 100px 0 40px;
      border-radius: 30px;
      font-size: 20px;
      line-height: 40px;
      color: #000;
      background-color: #D3EDFB;
      background-position: 400px 60px;
      background-repeat: no-repeat;
      background-size: 160px;
      &:nth-child(1) {
        background-image: url("../../assets/img/website/sa-1.png");
      }
      &:nth-child(2) {
        background-image: url("../../assets/img/website/sa-2.png");
      }
      &:nth-child(3) {
        background-image: url("../../assets/img/website/sa-3.png");
      }
      &:nth-child(4) {
        background-image: url("../../assets/img/website/sa-4.png");
      }
    }
  }
}
</style>
